import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import color from "../common/styles/color";
import MEDIA from "../common/styles/media";

//linear-gradient(to left, ##2e967f, #0aa3ad)
// linear-gradient(to left, #F77DD6, #0E5E57D)

const PurpleButton = styled.a`
  background: linear-gradient(45deg, #12c2e9, #c471ed, #f64f59);//linear-gradient(88deg, #8c99f9, #7ddde5);
  color: ${color.white};
  font-size: 18px;
  transition: all 0.2s ease;
  border-radius: 5px;
  //border: ${color.white} 2px;
  box-shadow: none;
  font-family: inherit;
  font-weight: 700;
  padding: 12px;
  width: 80px;
  text-align: center;
  display: inline-block;
  outline: none;
  cursor: pointer;
  &:hover {
    background: linear-gradient(315deg, #12c2e9, #c471ed, #f64f59);
    color: ${color.white};
    transform: scale(0.98);
  }
  ${MEDIA.MOBILE`
    font-size: 18px;
    padding: 0.625rem;
  `};
`;

const StartButton = ({ children }) => (
  <PurpleButton href="https://www.google.com" title="start">
    {children}
  </PurpleButton>
);

StartButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default StartButton;
