import React from "react";
import styled from "styled-components";
import { StaticQuery, Link, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoIcon from "../../assets/logoIcon.svg";

import MEDIA from "../../common/styles/media";
import color from "../../common/styles/color";
import StartButton from "../StartButton";
import LoginButton from "../LoginButton";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        footerYaml {
          socials {
            icon
            name
            link
            color
          }
        }
      }
    `}
    render={({ footerYaml: data }) => {
      return (
        <Wrapper>
          <Container>
            <Box>
              <Column>

                <StartButton>Join</StartButton>
              </Column>
              <Column>
                <h4>Learn more</h4>
                <ul>
                  <li>
                    <Link to="/students" title="Student">
                      Students
                    </Link>
                  </li>
                  <li>
                    <Link to="/teachers" title="Teachers">
                      Teachers
                    </Link>
                  </li>
                  {/*<li>
                    <Link to="/research" title="Teachers">
                      #EDEOSkills
                    </Link>
                  </li>*/}
                  <li>
                    <Link to="/blog" title="Schools">
                      Schools
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Schools">
                      Mobile
                    </Link>
                  </li>
                  {/*<li>
                    <Link to="/blog" title="Research">
                      Mobile App
                    </Link>
                  </li>*/}
                </ul>
              </Column>
              <Column>
                <h4>Courses</h4>
                <ul>
                  <li>
                    <Link to="/blog" title="Biology">
                      GCSE
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Chemistry">
                      A-level
                    </Link>
                  </li>

                  <li>
                    <Link to="/blog" title="Chemistry">
                      BTEC
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Physics">
                      Key Stage 3
                    </Link>
                  </li>
                  {/*<li>
                    <Link to="/blog" title="Maths">
                      University
                    </Link>
                  </li>*/}
                </ul>
              </Column>
              {/*<Column>
                <h4>Subjects</h4>
         <ul>
                  <li>
                    <Link to="/blog" title="Biology">
                      Biology
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Chemistry">
                      Chemistry
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Physics">
                      Physics
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Maths">
                      Maths
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Maths">
                      Computing
                    </Link>
                  </li>
                </ul>
              </Column>*/}
              {/*<Column>
              <h4>Resources</h4>
          <ul>
                  <li>
                    <Link to="/blog" title="Blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Science Articles">
                      Explore
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Blog">
                      STEAM Careers
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Blog">
                      Graphics & Diagrams
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Blog">
                      Teacher CPD
                    </Link>
                  </li>
                </ul>
              </Column>*/}
              <Column>
                <h4>Support</h4>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com"
                      title="Help"
                    >
                      Help & FAQs
                    </a>
                  </li>
                  <li>
                    <Link to="/community-guidelines" title="legal">
                      Community Guidelines
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Advisary board">
                      Acknowledgements{/*Advisory board*/}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Community">
                      Feedback{/*Partners*/}
                    </Link>
                  </li>
                </ul>
              </Column>
              <Column>
                <h4>EDEO.</h4>
                <ul>
                  <li>
                    <Link to="/terms" title="legal">
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" title="legal">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/community-guidelines" title="legal">
                      Community Guidelines
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Advisary board">
                      Acknowledgements{/*Advisory board*/}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" title="Community">
                      Feedback{/*Partners*/}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://www.google.com"
                      title="Help"
                    >
                      Help & FAQs
                    </a>
                  </li>
                </ul>
              </Column>
              <Column>
                {/*<h4>Follow us</h4>*/}
                <div className="FooterTitle">EDEO.</div>
                <Social>
                  {data.socials.map(social => (
                    <SocialButtons
                      target="_blank"
                      rel="noopener noreferrer"
                      href={social.link}
                      title={social.name}
                      key={social.name}
                      color={social.color}
                    >
                      <FontAwesomeIcon icon={["fab", social.icon]} fixedWidth />
                      {/*social.name*/}
                    </SocialButtons>
                  ))}
                </Social>
                <ul>
                  <li>
                    <a href="mailto:elise@edeogo.com">
                      hello@edeogo.com
                    </a>
                  </li>
                </ul>
                <div>


                </div>
              </Column>

            </Box>
            {/*<Bottom>
                    <div>
                <h3>EDEO</h3>
                <Link to="/" id="Return Home" title="Return Home">
                    <span>
                      <img src={logoIcon} alt="logoIcon" />
                    </span>
                  </Link>
                  </div>
              <ButtonContainer>
                <StartButton>Start learning</StartButton>
                  </ButtonContainer>
              </Bottom>*/}

          </Container>
        </Wrapper >
      );
    }}
  />
);

export default Footer;


export const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  //box-shadow: inset 0px 0px 50px 0px rgba(241, 243, 245, 1);
  background: #222637;
`;

const Container = styled.div`
  max-width: 2000px;
  width: 100%;
  ${MEDIA.TABLET`
    max-width: 700px;
  `};
`;

export const Box = styled.div`
  display: flex;
  margin: 0 auto;
  color: #fff;//#7289DA;
  //color: ${color.white};
  max-width: 70%;
  display: grid;
  grid-template-columns: 15% 17% 17% 17% 17% 17%;
  font-size: 16px;
  letter-spacing: -0.3px;
  border-bottom: 1px solid #7289DA;
  padding: 80px 0 60px;
  justify-content: space-between;
  ${MEDIA.TABLET`
    grid-template-columns: 105px 105px 105px 105px;
    padding: 60px 0 20px;
  `};
  h4 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 12px;
    padding: 0px 6px 0px 0px;
  }
  ul {
    list-style: none;
    color: ${color.white};
  }
  ul a {
    text-decoration: none;
    margin: 0;
    transition: 0.15s;
    display: block;
    padding: 0px 6px 0px 0px;
    line-height: 1.6;
    font-weight: 400;
    font-size: 14px;
    svg {
      margin-right: 1px;
      font-size: 100%;
    }
    &:hover {
      color: #7289DA;
      //color: ${color.purple};
      //font-weight: 700;
      text-decoration: underline;
    }
  }
  ${MEDIA.MOBILE`
    display: none
  `}
`;

const Column = styled.div`
  text-align: left;
  justify-self: left;
  ${MEDIA.TABLET`
    margin-bottom: 40px;
  `};
  span svg {
    padding-bottom: 8px;
  }
  p {
    //color: ${color.greyBlue};
    line-height: 1.6;
    font-size: 12px;
    letter-spacing: -0.2px;
    svg {
      font-size: 100%;
    }
    /*a {
      text-decoration: underline;
      transition: 0.15s;
      &:hover {
        text-decoration: underline;
        //color: #7289DA;
      }
    }*/
  }
`;

const Social = styled.div`

`;

const SocialButtons = styled.a`
  color: #fff;
  svg {
    padding: 2px 2px 10px 0;
  }
  &:hover {
    color: ${({ color }) => color || "grey"} !important;
  }
`;

/*export const Bottom = styled.div`
  display: flex;
  margin: 0 auto;
  color: ${color.white};
  max-width: 70%;
  font-size: 16px;
  letter-spacing: -0.3px;
  padding: 40px 0 30px;
  ${MEDIA.TABLET`
    grid-template-columns: 105px 105px 105px 105px;
    padding: 60px 40px 20px 40px;
  `};
  //border-bottom: 1px solid #7289DA;
  //padding: 80px 40px 60px 40px;
  justify-content: space-between;
`

export const SignUpBox = styled.div`
  padding: 40px 40px 40px 40px;
  justify-content: space-between;
  justify-items: flex-start;
  display: grid;
  grid-template-columns: 300px 150px;
  grid-gap: 40px;
  ${MEDIA.TABLET`
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
    align-items: center
  `};
  h3 {
    //color: ${color.purple};
    margin-bottom: 2px;
    font-size: 19px;
    font-weight: 900;
    letter-spacing: -0.3px;
  }
  p {
    //color: ${color.grey};
    font-size: 19px;
    font-weight: 700;
    letter-spacing: -0.3px;
  }
  a {
    padding: 10px;
    border-radius: 11px;
    font-size: 15px;
  }
  ${MEDIA.MOBILE`
    text-align: center;
    align-items: center
  `}
`;

const ButtonContainer = styled.div`
  a {
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
  }
  ${MEDIA.MOBILE`
      a {
      font-size: 18px;
    }
  `}
`;
*/

/*<p>
                    <FontAwesomeIcon icon={["far", "copyright"]} fixedWidth />{" "}
                    2019 EDEO
                  </p>
                  <p>
                    <Link to="/terms" title="Terms">
                      Terms
                    </Link>{" "}
                    &{" "}
                    <Link to="/privacy-policy" title="Privacy Policy">
                      Privacy
                    </Link>
                  </p>*/