export const blue = "#0087ff";
export const blueLight = "#eff7ff";

export const green = "#00cc81";
export const greenLight = "#eafff7";

export const grey = "#6a676e";
export const greyLight = "#f9f9fa";

export const black = "#2b2a2c";
export const blackLight = "#c4c2c6";

export const white = "#FFFFFF";

export const pink = "#ff6582";
export const pinkLight = "#ff6582";

export const red = "#ff6b6d";
export const redLight = "#fff4f4";

export const purple = "#5065FF";
export const purpleLight = "#f7f4ff";

export const greyLink = "#aaaaba";
export const greyBlue = "#646F79";

export const greyBackground = "#f8f9fb";
export const darkBackground = "#222637";

export const twitter = "#1da1f2";
export const instagram = "#e1306c";
export const facebook = "#3b5998";

export const purpleGradient = "linear-gradient(80deg, #5b6df7, #a98cf8)";
export const blueGradient = "linear-gradient(88deg, #8c99f9, #7ddde5)";
export const pinkGradient = "linear-gradient(268deg, #ce78db, #fc7a90)";
export const greenGradient = "linear-gradient(263deg, #67e68b, #29d6e6)";
export const yellowGradient = "linear-gradient(30deg, #f1c8a5, #e5e57d)";

export default {
  blue,
  blueLight,
  green,
  greenLight,
  grey,
  greyLight,
  black,
  blackLight,
  white,
  pink,
  pinkLight,
  red,
  redLight,
  purple,
  purpleLight,
  greyLink,
  greyBlue,
  greyBackground,
  darkBackground,
  twitter,
  instagram,
  facebook,
  purpleGradient,
  blueGradient,
  pinkGradient,
  greenGradient,
  yellowGradient
};
