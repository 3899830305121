import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import MEDIA from "../../common/styles/media";
import color from "../../common/styles/color";
import StartButton from "../StartButton";
import LoginButton from "../LoginButton";
import logo from "../../assets/betalogo.svg";
import DrawerToggleButton from "./SideDrawer/DrawerToggleButton";
import "./Menu.css";
/*
NEED TO ADD SIDE DRAWER FOR MOBILE MENU (see Zeplin):

Desktop/Tablet
EDEO STEM       Blog | Students | Teachers | [Login] | [Sign up]

Mobile 
EDEO       [Login] | [Sign up] | ** HAMBURGER ICON **

Mobile Drawer:
Blog
Students
Teachers
Login
Sign up

*/

const Menu = ({ data, toggleSideDrawer, visible, topPage }) => {
  /*
  let headerClassName = "";
  if (!visible) {
    headerClassName = "hide";
  } else if (!topPage) {
    headerClassName = "styledMenu";
  }
  */
  return (
    <Header>
      <div className="NavBar">
        <Logo>
          <Link to="/" title="Home">
            <div className="FooterTitle">EDEO.</div> {/*<img src={logo} alt="logo" />*/}
          </Link>
        </Logo>
        <MenuItems>
          <li>
            <MenuItem to="/" activeClassName="active" title="Students">
              Home
            </MenuItem>
          </li>
          <li>
            <MenuItem to="/students" activeClassName="active" title="Students">
              Students
            </MenuItem>
          </li>
          <li>
            <MenuItem to="/teachers" activeClassName="active" title="Teachers">
              Teachers
            </MenuItem>
          </li>
          <li>
            <MenuItem to="/schools" activeClassName="active" title="Schools">
              Schools
            </MenuItem>
          </li>
          <li>
            <MenuItem to="/parents" activeClassName="active" title="Parents">
              Parents
            </MenuItem>
          </li>
          <li>
            <MenuItem to="/research" activeClassName="active" title="Research">
              Research
            </MenuItem>
          </li>
          {/*} <li>
            <MenuItem to="/research" activeClassName="active" title="Schools">
              #EDEOSkills
            </MenuItem>
          </li>
          <li>
            <MenuItem to="/blog" activeClassName="active" title="Blog">
              Blog
            </MenuItem>
  </li>*/}
        </MenuItems>
        <Spacer />

        <div className="ButtonsContainer">
          <div className="Button">
            <LogIn
              href={data.routes.login}
              rel="noopener noreferrer"
              title="Log in"
            >
              <LoginButton>Login</LoginButton>
            </LogIn>
          </div>
          <div className="Button">
            <SignUp
              href={data.routes.signup}
              rel="noopener noreferrer"
              title="Sign up"
            >
              <StartButton>Sign up</StartButton>
            </SignUp>
          </div>
        </div>

        <HamburgerMenu>
          <DrawerToggleButton toggleSideDrawer={toggleSideDrawer} />
        </HamburgerMenu>
      </div>
    </Header>
  );
};

Menu.propTypes = {
  data: PropTypes.object.isRequired
};

const MenuQuery = props => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        menuYaml {
          routes {
            home
            login
            signup
          }
          menu {
            name
            to
            desktop
          }
        }
      }
    `}
    render={({ menuYaml }) => <Menu data={menuYaml} {...props} />}
  />
);

export default MenuQuery;

const Text = styled.header`
  font-size: 34px;
  color: #fff;
  font-weight: 900;
`

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;

  font-size: 17px;
  letter-spacing: -0.5px;
  color: ${color.linkColor};
  z-index: 9997;

  &.hide {
    top: -70px;
  }

  //&.styledMenu {
  //background-color: #ffffff;
  //box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  //}
  ${MEDIA.TABLET`
    height: 60px;
    &.hide {
      top: -60px;
    }
  `}
`;

const Logo = styled.div`
  a {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${MEDIA.MOBILE`
      padding-left: 75px;
    `};
  }
  ${MEDIA.TABLET`
    margin-right: auto;
  `}
  ${MEDIA.MOBILE`
    width: 110px;
    margin-right: auto;
  `}
`;

const Spacer = styled.div`
  flex: 1;
`;

const MenuItems = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  align-content: center;
  margin-left: 40px;
`;

const MenuItem = styled(Link)`
  margin: 0 15px;
  padding: 0;
  transition: 0.2s ease;
  color: ${color.white};
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: 600;
  line-height: 2;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75%;
  }
  &:hover {
    color: ${color.white};
    font-weight: 600;
  }
  &:active {
    color: ${color.white};
    font-weight: 700;
  }
  &:selected {
    color: ${color.white};
    text-decoration: 1px solid underline;
  }
  ${MEDIA.TABLET`
      display: none;
  `}
  ${MEDIA.MOBILE`
      display: none;
  `}
`;

const LogIn = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  a {
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 4px;
  }
  ${MEDIA.MOBILE`
      a {
      font-size: 15px;
      margin-right: 5px
    }
  `}
`;

const SignUp = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 15px;
  max-height: 35px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  a {
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 4px;
  }
  ${MEDIA.MOBILE`
    padding-left: 0;
    padding-right: 10px; 
    a {
      font-size:15px;
    }
  `}
`;

const HamburgerMenu = styled.div`
  margin-left: 5px;
  ${MEDIA.MIN_TABLET`
    display: none;
  `}
  ${MEDIA.MOBILE`
    margin-left: 0px;
  `}
`;
